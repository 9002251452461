import { API_URL } from "../../constants";
import { axiosInstance } from "../_base/axios.instance";

export async function getAllBudget({ page }){

    const BUDGETQUANTITY = 5;
    const response = await axiosInstance.get(
        `${API_URL}/budgets/getAll?page=${page}&size=${BUDGETQUANTITY}`,
        {}
    );
    return response;
}
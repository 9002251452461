import { useEffect, useState } from "react";
import "./editPost.style.css";
import { useNotify, usePage } from "../../../hook";
import { getAllPosts } from "../../../api/post/getAllPosts.api";
import { RemovePostCard } from "../RemovePostCard/removePostCard.component";
import { EditPostInfo } from "../index";

export function EditPost() {
  const [posts, setPosts] = useState([]);
  const { page, handlePreviousPage, handleNextPage } = usePage();
  const [postSelected, setPostSelected] = useState(undefined);
  const [postSelectedIndex, setPostSelectedIndex] = useState({
    post1: false,
    post2: false,
    post3: false,
    post4: false,
    post5: false,
    post6: false,
    post7: false,
    post8: false,
  });
  const [hasNextPage, setHasNextPage] = useState(false);
  const { notify } = useNotify();

  async function fetchPosts() {
    try {
      const response = await getAllPosts({ page: page, POSTQUANTITY: 8 });
      setPosts(response.data.posts);
      setHasNextPage(response.data.hasNextPage);
    } catch (e) {
      notify(e.response.data.message);
    }
  }

  function handleSelected(post, index, isselected) {
    if (isselected) {
      setPostSelected(post);
      setPostSelectedIndex((oldPostSelectedIndex) => {
        const resetState = {};
        Object.keys(oldPostSelectedIndex).forEach((key) => {
          resetState[key] = false;
        })
        return resetState;
      });
    } else {
      setPostSelectedIndex((oldPostSelectedIndex) => {
        const resetState = {};
        Object.keys(oldPostSelectedIndex).forEach((key) => {
          key === `post${index}` ?  resetState[key] = true : resetState[key] = false;
        })
        return resetState;
      });
      setPostSelected(post);
    }
  };


  function removePostNotSelected(){
    return (
        <>
      <form className="removePost_form">
        <strong>Selecione o Post a alterar:</strong>
        <div className="removePost_form--slideDiv">
          {posts?.map((post, index) => (
            <RemovePostCard
              postSelectedIndex={postSelectedIndex}
              handlePostSelected={handleSelected}
              key={index}
              index={index + 1}
              post={post}
              postSelected={postSelected}
            />
          ))}
        </div>
      </form>
      <div className="removePost_div--button">
        <button
          className="removePost_button--page"
          onClick={handlePreviousPage}
        >
          Anterior
        </button>
        <span>{page}</span>
        <button
          className="removePost_button--page"
          onClick={hasNextPage ? handleNextPage : null}
        >
          Próximo
        </button>
      </div>
    </>
    )
  };

  useEffect(()=>{
    fetchPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
        {postSelected ?  <EditPostInfo handleSelected={handleSelected}  postId={postSelected.id}/> : removePostNotSelected()}
    </>
  );
}

import "./budgetList.style.css";
import { BudgetItem } from "../index";
import { usePage } from "../../../hook";
import { useState } from "react";
import { getAllBudget } from "../../../api/budget/getAllBudget.api";
import { useEffect } from "react";

export function BudgetList() {
  const { handleNextPage, handlePreviousPage, page } = usePage();
  const [budgetInfo, setBudgetInfo] = useState([]);
  const [ hasNextPage, setHasNextPage ] = useState(true);
  async function fetchBudgets(){
    try {
      const response = await getAllBudget({ page: page });
      setBudgetInfo(response.data.budgets);
      setHasNextPage(response.data.hasNextPage);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    fetchBudgets();
  }, [page]);

  return (
    <>
      <ul className="budgetList_ul">
        {budgetInfo.map((budgetInfoItem, index) => {
          return (
            <>
              <li key={index}>
                <BudgetItem
                  BudgetId={budgetInfoItem.id}
                  name={budgetInfoItem.name}
                  city={budgetInfoItem.city}
                  state={budgetInfoItem.state}
                  projectTypology={budgetInfoItem.projectTypology}
                />
              </li>
            </>
          );
        })}
        <div className="budgetList_ul--buttonDiv">
          <button
            onClick={handlePreviousPage}
            className="budgetList_ul--button"
          >
            anterior
          </button>
          <p>{page+1}</p>
          <button onClick={()=> hasNextPage ? handleNextPage():null} className="budgetList_ul--button">
            próxima
          </button>
        </div>
      </ul>
    </>
  );
}

import { useHandleHover } from "../../../hook/index.jsx";
import "./slideCard.style.css";
import { ImageShow } from "../../../constants/functions/index.js";
import { Link } from "react-router-dom";

export function SlideCard({ onClick, pic, title, postId }){

    const { hovered, handleMouseEnter, handleMouseLeave } = useHandleHover();

    return(
        <Link
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} 
        onClick={onClick}
        className="slidecard_link"
        to={`/post/${postId}`}>
            <strong className={hovered ? "slidecard_strong show" : "slidecard_strong"}>{title}</strong>
            <img className="slidecard_img " src={ImageShow(pic)} alt="imagem do projeto" />
        </Link>
    );
};
import slidelogo from "../../../assets/ico/slide_logo.svg";
import arrowdown from "../../../assets/ico/arrowdown-ico.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slide.style.css";
import Slider from "react-slick";
import { SlideCard } from "../index";
import { useState, useEffect } from "react";


export function Slide( { slides }) {

    const [contentPosition, setContentPosition] = useState(0);
    const [ isDragging, setIsDragging ] = useState(false);

    const handleClick = (event) => {
        event.preventDefault();
        window.scrollTo({
            top: contentPosition,
            behavior: 'smooth'
        });
    };

    const settings = {
        dots: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        className: "slide_section--img",
        beforeChange: () => setIsDragging(true),
        afterChange: () => setIsDragging(false)
    };

    function handleSlideClick(e){
        
        if(isDragging){
            e.preventDefault();
        };
    };

    useEffect(() => {
        const content = document.getElementById("home_card-section");
        const header = document.getElementById("header");

        if (content && header) {
            const headerHeight = header.offsetHeight;
            setContentPosition(content.offsetTop - headerHeight);
        }
    }, []);

    return (
        <>
            <section className="slide_section">
                <img className="slide_section--ico" alt="logo da basílio arquitetos" src={slidelogo} />
                <Slider {...settings}>
                    {slides.map((slide, index)=>{
                        return (
                            <SlideCard onClick={handleSlideClick} postId={slide.postId} key={index} pic={slide.mainImage}/>
                        )
                    })}
                </Slider>
                <a className="slide_arrowdown"
                 href="#home_card-section"
                 onClick={handleClick}
                 >
                    <img alt="seta de navegação que leva a seção de projetos" src={arrowdown}/>
                </a>
            </section>
        </>
    );
};
import { useState } from "react";
import { getAllCities } from "../../api/ibge/cities/getAllCities.api";
import { useNotify } from "../useNotify/useNotify.hook";

export function useCities(state, states){

    const [ cities, setCities] = useState([]);
    const { notify } = useNotify();

    async function fetchCities(){
        try{
            let uf = states.find(i => i.nome === state);
            uf = uf?.sigla;
            const response = await getAllCities(uf);
            const citiesNames = response.data.map(city => city.nome);
            setCities(citiesNames);
        } catch(error){
            notify(error.message);
        };
    };

    return { cities, fetchCities }; 
}
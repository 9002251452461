import { API_URL } from "../../constants";
import { axiosInstance } from "../_base/axios.instance";

export async function findAllPost({ page, POSTQUANTITY, title }){
    console.log(title)
    const response = await axiosInstance.get(
        `${API_URL}/posts/findAllPost/${title}?page=${page}&size=${POSTQUANTITY}&sort=creationDate,DESC`,
        {}
    );
    return response;
};
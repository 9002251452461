import { useState } from "react";
import { getAllStates } from "../../api/ibge/states/getAllStates.api";
import { useNotify } from "../useNotify/useNotify.hook";

export function useStates(){

    const [ states, setStates] = useState([]);
    const { notify } = useNotify();

    async function fetchStates(){
        try{
            const response = await getAllStates();
            setStates(response.data);
        } catch(error){
            notify(error.message);
        };
    };

    return { states, fetchStates }; 
}

import "./removeSlide.style.css";
import { useNotify, usePage } from "../../../hook/index";
import { getAllSlides } from "../../../api/slide/getAllSlides.api";
import { removeSlide } from "../../../api/slide/removeSlide.api";
import { RemoveSlideCard } from "../index";
import { useCallback, useEffect, useState } from "react";
import useGlobalUser from "../../../context/user.context";
import { useNavigate } from "react-router-dom";
import { validateToken } from "../../../constants/functions";

export function RemoveSlide() {
  const { page, handlePreviousPage, handleNextPage } = usePage();
  const [slides, setSlides] = useState([]);
  const [slideSelected, setSlideSelected] = useState();
  const [hasNextPage, setHasNextPage] = useState(false);
  const [slideSelectedIndex, setSlideSelectedIndex] = useState({
    slide1: false,
    slide2: false,
    slide3: false,
    slide4: false,
    slide5: false,
  });
  const { notify } = useNotify();
  const [ user, setUser ] = useGlobalUser();
  const navigate = useNavigate();

  async function handleSubmit(event) {
    event.preventDefault();
    validateToken(navigate, setUser, notify);
    try{
      const response = await removeSlide({slideId: slideSelected.id});
      notify(response.data.detail)
      fetchSlides();
    }catch(e){
      notify(e.response.data.message);
    }
  }


  function handleSelected(slide, index, isselected) {
    if (isselected) {
      setSlideSelected(slide);
      setSlideSelectedIndex((oldSlideSelectedIndex) => {
        const resetState = {};
        Object.keys(oldSlideSelectedIndex).forEach((key) => {
          resetState[key] = false;
        })
        return resetState;
      });
    } else {
      setSlideSelectedIndex((oldSlideSelectedIndex) => {
        const resetState = {};
        Object.keys(oldSlideSelectedIndex).forEach((key) => {
          key === `slide${index}` ?  resetState[key] = true : resetState[key] = false;
        })
        return resetState;
      });
      setSlideSelected(slide);
    }
  };

  const fetchSlides = useCallback(async () => {
    try {
      const response = await getAllSlides({ SLIDEQUANTITY: 6 });
      setSlides(response.data.slides);
      setHasNextPage(response.data.hasNextPage);
    } catch (e) {
      notify(e.response.data.message);
    }
  }, []);

  useEffect(() => {
    fetchSlides();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form className="removeSlide_form" onSubmit={handleSubmit}>
        <strong>Selecione o slide a remover:</strong>
        <div className="removeSlide_form--slideDiv">
          {slides.map((slide, index) => (
            <RemoveSlideCard
              handleSlideSelected={handleSelected}
              
              key={index}
              index={index+1}
              slide={slide}
              slideSelectedIndex={slideSelectedIndex}
            />
          ))}
        </div>
        <div className="removeSlide_form--buttonDiv">
          <button
            disabled={!slideSelected}
            className="removeSlide_button--page"
          >
            Remover
          </button>
        </div>
      </form>
      <div className="removeSlide_div--button">
        <button
          className="removeSlide_button--page"
          onClick={handlePreviousPage}
        >
          Anterior
        </button>
        <span>{page}</span>
        <button
          className="removeSlide_button--page"
          onClick={hasNextPage ? handleNextPage : null}
        >
          Próximo
        </button>
      </div>
    </>
  );
}

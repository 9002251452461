import { API_URL } from "../../constants";
import { axiosInstance } from "../_base/axios.instance";

export async function getAllPosts({ page, POSTQUANTITY }){
    
    const response = await axiosInstance.get(
        `${API_URL}/posts/getAll?page=${page}&size=${POSTQUANTITY}&sort=creationDate,DESC`,
        {}
    );
    return response;
};
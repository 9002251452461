import { axiosInstance } from "../_base/axios.instance"
import { API_URL } from "../../constants"

export async function checkToken({ token }) {
    const response = await axiosInstance.get(
      `${API_URL}/auth/checkToken`,
      {
        params: {
            token: token,
        }
      }
    )
    return response;
  };
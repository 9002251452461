import "./switch.style.css";

export function Switch( { setState } ){
    return(
        <>
            <label className="switch">
                <input type="checkbox"/>
                <span onClick={setState} className="slider"/>
            </label>
        </>
    );
};
import "./removePostCard.style.css";
import { ImageShow } from "../../../constants/functions";

export function RemovePostCard({ post, handlePostSelected, postSelectedIndex, index }) {

  function handleSelected() {
    if (postSelectedIndex[`post${index}`]) {
      handlePostSelected(undefined, index, true);
    } else {
      handlePostSelected(post, index, false);
    }
  }

  return (
    <>
      <div
        onClick={handleSelected}
        className={
          postSelectedIndex[`post${index}`]
            ? "removePostCard_card--div removePostSelected"
            : "removePostCard_card--div"
        }
      >
        <img
          className="removePostCard_img"
          src={ImageShow(post.mainImage)}
          alt="Imagem do post"
        />
        <strong className="removePostCard_title--strong">
          {post.postTitle}
        </strong>
      </div>
    </>
  );
}

import "./search.style.css";
import { Header, Footer, Card } from "../../Components/index";
import search from "../../../assets/ico/search.svg";
import { findAllPost } from "../../../api/post/findAllPost.api";
import { useFormInputs, usePage } from "../../../hook";
import { useState } from "react";
import { Link } from "react-router-dom";
import arrowDown from "../../../assets/ico/arrow_down.svg";
import { useEffect } from "react";

export function Search() {
  const { page, handleNextPage, handlePreviousPage } = usePage();
  const [posts, setPosts] = useState([]);
  const { formInputs, handleChange } = useFormInputs({ title: "" });
  const [isSearched, setIsSearched] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);

  async function fetchPosts() {
    try {
      const response = await findAllPost({
        page: page,
        POSTQUANTITY: 8,
        title: formInputs.title,
      });
      setPosts((oldPosts) => [...oldPosts, ...response.data.posts]);
      setIsSearched(true);
      setHasNextPage(response.data.hasNextPage);
    } catch (e) {
      console.log(e);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (formInputs.title.length === 0) {
      setPosts([]);
    } else {
      try {
        const response = await findAllPost({
          page: page,
          POSTQUANTITY: 8,
          title: formInputs.title,
        });
        setPosts(response.data.posts);
        setIsSearched(true);
      } catch (e) {
        console.log(e);
      }
    }
  }

  useEffect(()=>{
    fetchPosts();
  }, [page])

  return (
    <>
      <Header selected={"Find"} />
      <main className={`search_main ${isSearched ? "searched" : ""}`}>
        <form className="search_form" onSubmit={handleSubmit}>
          <h1 className="search_main--title">buscar</h1>
          <div className="search_main--div">
            <input
              name="title"
              onChange={handleChange}
              className="search_main--input"
              placeholder="escreva para pesquisar"
            />
            <button><img className="search_ico" src={search} alt="ícon de busca" /></button>
          </div>
        </form>
        <div className={`posts_container ${isSearched && posts.length === 0 ? "isEmpty" : ""}`}>
          {isSearched && posts.length ===0 ? <p className="search_legend">Nenhum projeto encontrado</p>: null}
          {posts.map((post, index) => (
            <Card key={index} post={post} />
          ))}
        </div>
        {isSearched && posts.length == 8 ? (
            <div className="arrowDown_div">
          <Link
            className="arrowDown_link"
            onClick={() => (hasNextPage ? handleNextPage() : null)}
          >
            <img
              src={arrowDown}
              alt="Flecha pra baixo para carregar mais posts"
            />
          </Link>
        </div>
        ): null}
        
      </main>
      <Footer />
    </>
  );
}

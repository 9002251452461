import { toast } from "react-toastify";

export function useNotify() {
  const notify = (text) => {
    toast.dismiss();
    toast(text);
  };
  return {
    notify,
  };
}

import "./slidePostCard.style.css";
import { ImageShow } from "../../../constants/functions";

export function SlidePostCard({ post, handlePostSelected, postSelectedIndex, index }) {

  function handleSelected() {
    if (postSelectedIndex[`post${index}`]) {
      handlePostSelected(undefined, index, true);
    } else {
      handlePostSelected(post, index, false);
    }
  }

  return (
    <>
      <div
        onClick={handleSelected}
        className={
          postSelectedIndex[`post${index}`]
            ? "slidePostCard_card--div slideSelected"
            : "slidePostCard_card--div"
        }
      >
        <img
          className="slidePostCard_img"
          src={ImageShow(post.mainImage)}
          alt="Imagem do post"
        />
        <strong className="slidePostCard_title--strong">{post.title}</strong>
      </div>
    </>
  );
}

import "./homeHeader.style.css";
import logo from "../../../assets/ico/logo.svg";
import search from "../../../assets/ico/search.svg";
import { Link } from "react-router-dom";
import useGlobalUser from "../../../context/user.context";
import { useEffect, useState } from "react";
import { HeaderModal } from "../HeaderModal/headerModal.component";

export function HomeHeader({ selected }) {
  const [user] = useGlobalUser();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [headerTop, setHeaderTop] = useState(0);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  useEffect(() => {
    // Captura a posição inicial do header
    const header = document.getElementById("header");
    setHeaderTop(header.offsetTop);

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Verifica se o scroll ultrapassou a posição original do header
      if (currentScrollY > headerTop) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [headerTop]);

  return (
    <header id="header" className={`homeHeader_main ${isSticky ? "sticky" : ""}`}>
      <img className="homeHeader_img--logo" src={logo} alt="Logo do site" />
      <nav className="homeHeader_nav">
        <ul className="homeHeader_nav--ul">
          <li className="homeHeader_nav--ul_li">
            <Link
              className={
                selected === "Home"
                  ? "homeHeader_nav--li_text selected"
                  : "homeHeader_nav--li_text"
              }
              to="/"
            >
              Início
            </Link>
          </li>
          <li className="homeHeader_nav--ul_li">
            <Link
              to={"/busca"}
              className={
                selected === "Find"
                  ? "homeHeader_nav--li_text selected"
                  : "homeHeader_nav--li_text"
              }
            >
              Projetos
            </Link>
          </li>
          <li className="homeHeader_nav--ul_li">
            <Link
              className={
                selected === "Contact"
                  ? "homeHeader_nav--li_text selected"
                  : "homeHeader_nav--li_text"
              }
              to="/contato"
            >
              Contato
            </Link>
          </li>
          <li className="homeHeader_nav--ul_li">
            <Link
              className={
                selected === "Instagram"
                  ? "homeHeader_nav--li_text selected"
                  : "homeHeader_nav--li_text"
              }
              target="_blank"
              to="https://www.instagram.com/basilioarquitetos/"
            >
              Instagram
            </Link>
          </li>
          <li className="homeHeader_nav--ul_li">
            <Link
              className={
                selected === "Login"
                  ? "homeHeader_nav--li_text selected"
                  : "homeHeader_nav--li_text"
              }
              to="/login"
            >
              {user ? "Meu Perfil" : "Login"}
            </Link>
          </li>
        </ul>
      </nav>
      {/* Ícone do menu sanduíche */}
      <div className="hamburguer" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <Link className="homeHeader_search--link" to="/Busca">
        <img
          className="homeHeader_img--search"
          src={search}
          alt="ícone para barra de busca"
        />
      </Link>
      <HeaderModal
        selected={selected}
        opened={menuOpen}
        toggleModal={toggleMenu}
      />
    </header>
  );
}

import "./editSlideOrderCard.style.css";
import { ImageShow } from "../../../constants/functions";

export function EditSlideOrderCard({ slide, handleOrder }) {

  function handleChange(event){
    slide.slideOrder = parseInt(event.target.value);
    handleOrder(slide);
  }

  return (
    <>
      <div className="editSlideOrderCard_card--div">
        <img
          className="editSlideOrderCard_img"
          src={ImageShow(slide.mainImage)}
          alt="Imagem do slide"
        />
        <div className="editSlideOrderCard_select--div">
          <span>Ordem do slide</span>
          <select onChange={handleChange} value={slide.slideOrder}>
            <option>{1}</option>
            <option>{2}</option>
            <option>{3}</option>
            <option>{4}</option>
            <option>{5}</option>
          </select>
        </div>
        <strong className="editSlideOrderCard_title--strong">
          {slide.postTitle}
        </strong>
      </div>
    </>
  );
}

import "./header.style.css";
import logo from "../../../assets/ico/logo.svg";
import search from "../../../assets/ico/search.svg";
import { Link } from "react-router-dom";
import useGlobalUser from "../../../context/user.context";
import { useState } from "react";
import { HeaderModal } from "../HeaderModal/headerModal.component";

export function Header({ selected }) {
  const [user] = useGlobalUser();
  const [menuOpen, setMenuOpen] = useState(false);
  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  return (
    <header className={`header_main ${selected === "Contact" || selected === "Find" ? "contactFindSelected" : null}`}>
      <img className="header_img--logo" src={logo} alt="Logo do site" />
      <nav className="header_nav">
        <ul className="header_nav--ul">
          <li className="header_nav--ul_li">
            <Link
              className={
                selected === "Home"
                  ? "header_nav--li_text selected"
                  : "header_nav--li_text"
              }
              to="/"
            >
              Início
            </Link>
          </li>
          <li className="header_nav--ul_li">
            <Link
              to={"/busca"}
              className={
                selected === "Find"
                  ? "header_nav--li_text selected"
                  : "header_nav--li_text"
              }
            >
              Projetos
            </Link>
          </li>
          <li className="header_nav--ul_li">
            <Link
              className={
                selected === "Contact"
                  ? "header_nav--li_text selected"
                  : "header_nav--li_text"
              }
              to="/contato"
            >
              Contato
            </Link>
          </li>
          <li className="header_nav--ul_li">
            <Link
              className={
                selected === "Instagram"
                  ? "header_nav--li_text selected"
                  : "header_nav--li_text"
              }
              target="_blank"
              to="https://www.instagram.com/basilioarquitetos/"
            >
              Instagram
            </Link>
          </li>
          <li className="header_nav--ul_li">
            <Link
              className={
                selected === "Login"
                  ? "header_nav--li_text selected"
                  : "header_nav--li_text"
              }
              to="/login"
            >
              {user ? "Meu Perfil" : "Login"}
            </Link>
          </li>
        </ul>
      </nav>
      {/* Ícone do menu sanduíche */}
      <div className="hamburguer" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <Link className="header_search--link" to="/Busca">
        <img
          className="header_img--search"
          src={search}
          alt="ícone para barra de busca"
        />
      </Link>
      <HeaderModal selected={selected} opened={menuOpen} toggleModal={toggleMenu}/>
    </header>
  );
}
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Footer, Header } from "../../Components";
import { useFormInputs, useVerifyEmail, useNotify } from "../../../hook";
import loginNext from "../../../assets/ico/loginNext.svg";
import loadinggif from "../../../assets/gif/loading.gif";
import "./login.style.css";
import { login } from "../../../api/auth/login.api";
import { checkEmail } from "../../../api/auth/checkEmail.api";
import useGlobalUser from "../../../context/user.context";
import { toast, ToastContainer } from "react-toastify";

export function Login() {
  const { formInputs, handleChange } = useFormInputs({
    email: "",
    password: "",
  });
  const [emailSelected, setEmailSelected] = useState(false);
  const [passwordSelected, setpasswordSelected] = useState(false);
  const {
    loadingEmailVerify,
    emailVerified,
    setEmailVerified,
    setLoadingEmailVerify,
  } = useVerifyEmail(formInputs.email);
  const [stayConected, setStayConected] = useState(false);
  const [user, setUser] = useGlobalUser();
  const navigate = useNavigate();
  const { notify } = useNotify();

  async function handleCheckEmail(event) {
    event.preventDefault();
    setLoadingEmailVerify(true);
    if (formInputs.email !== "") {
      try {
        await checkEmail({ email: formInputs.email });
        setEmailVerified(true);
        setLoadingEmailVerify(false);
      } catch (error) {
        error.response?.data?.message
          ? notify(error.response.data.message)
          : notify("Tempo limite excedido");
      }
    }
    setLoadingEmailVerify(false);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoadingEmailVerify(true);
    try {
      const response = await login({
        email: formInputs.email,
        password: formInputs.password,
        stayConected: stayConected,
      });
      setLoadingEmailVerify(false);
      setUser({
        id: response.data.id,
        email: response.data.email,
        name: response.data.name,
        token: response.data.token,
        role: response.data.role,
      });
    } catch (error) {
      error.response?.data?.message
        ? notify(error.response.data.message)
        : notify("Tempo limite excedido");
      setLoadingEmailVerify(false);
    }
  }

  function handleChangeStayConected() {
    setStayConected(!stayConected);
  }

  useEffect(() => {
    if (user) navigate("/Perfil");
  }, [user, navigate]);

  return (
    <>
      <Header selected="Login" />
      <main className="login_main">
        <form className="login_form" onSubmit={handleSubmit}>
          <div className="login_main--form_text">
            <h1 className="login_form--title">basílio</h1>
            <span className="login_form--subtitle">contas</span>
          </div>
          <div className="login_form--input_div">
            <div
              className={
                emailSelected
                  ? "login_form--email_input-div selected"
                  : "login_form--email_input-div"
              }
            >
              <input
                onClick={() => setEmailSelected(true)}
                onBlur={() => setEmailSelected(false)}
                type="email"
                placeholder="Email"
                className="login_form--email_input"
                name="email"
                onChange={handleChange}
              />

              {emailVerified ? null : (
                <button
                  onClick={handleCheckEmail}
                  disabled={loadingEmailVerify}
                  className="login_form--email_button"
                >
                  <img
                    alt="ícone de seta para passar para a próxima etapa de login"
                    src={loadingEmailVerify ? loadinggif : loginNext}
                    className="login_form--email_loginNext-img"
                  />
                </button>
              )}
            </div>
            {emailVerified ? (
              <div
                className={
                  passwordSelected
                    ? "login_form--email_input-div selected show"
                    : "login_form--email_input-div show"
                }
              >
                <input
                  onClick={() => setpasswordSelected(true)}
                  onBlur={() => setpasswordSelected(false)}
                  type="password"
                  placeholder="Senha"
                  className="login_form--email_input"
                  name="password"
                  onChange={handleChange}
                  value={formInputs.password}
                  autoComplete="current-password"
                />
                <button
                  className="login_form--email_button"
                  disabled={loadingEmailVerify}
                >
                  <img
                    alt="ícone de seta para passar para a próxima etapa de login"
                    src={loadingEmailVerify ? loadinggif : loginNext}
                    className="login_form--email_loginNext-img"
                  />
                </button>
              </div>
            ) : null}

            <div className="login_form--input_checkbox--div">
              <input
                type="checkbox"
                onChange={handleChangeStayConected}
                name="stayConected"
              />
              <span>Continuar conectado</span>
            </div>
          </div>
        </form>
        <Link className="login_main--password-forget">esqueceu a senha?</Link>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{ textAlign: "justify" }}
        />
      </main>
      <Footer />
    </>
  );
}

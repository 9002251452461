import { axiosInstance } from "../../_base/axios.instance";

export async function getAllCities(state) {
  const response = await axiosInstance.get(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/distritos?orderBy=nome`,
    {
      withCredentials: false
    }
  );
  return response;
};
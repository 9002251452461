import { useState } from "react";

export function useVerifyEmail(email) {
    const [emailVerified, setEmailVerified] = useState(false);
    const [loadingEmailVerify, setLoadingEmailVerify] = useState(false);

    
    function handleVerified() {
        setLoadingEmailVerify(true)
        if (email !== '' && emailVerified === false) {
            setEmailVerified(true);
            setLoadingEmailVerify(false)
        };
    }

    return {
        loadingEmailVerify,
        emailVerified,
        setEmailVerified,
        setLoadingEmailVerify,
        handleVerified
    };
};
import "./card.style.css";
import { useHandleHover } from "../../../hook/index.jsx";
import { ImageShow } from "../../../constants/functions/index.js";
import { Link } from "react-router-dom";

export function Card({ post }) {

    const { hovered, handleMouseEnter, handleMouseLeave } = useHandleHover();

    return (
        <Link
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} 
        className="card_link"
        to={`/post/${post.id}`}>
            <strong className={hovered ? "card_strong show" : "card_strong"}>{post.title}</strong>
            <img className="card_img " src={ImageShow(post.mainImage)} alt="imagem do projeto" />
        </Link>
    );
};
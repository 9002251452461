import "./budgetInfo.style.css";
import { Footer, Header } from "../../Components/index";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { getBudget } from "../../../api/budget/getBudget.api";
import { useNotify } from "../../../hook";

export function BudgetInfo() {
  const { budgetId } = useParams();
  const [ budget, setBudget ] = useState([]);
  const { notify } = useNotify();

  async function fetchBudgets(){
    
    try {
      const response = await getBudget({ budgetId: budgetId});
      setBudget(response.data);
    } catch (e) {
      notify(e.response.data.message);
    }
  };

  useEffect(() => {
    fetchBudgets();
  }, []);

  return (
    <>
      <Header />
      <main className="budgetInfo_main">
        <section className="budgetInfo_content--section">
          <div className="budgeInfo_content--itemDiv">
            <strong>Nome: </strong>
            <span>{budget.name}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Sexo: </strong>
            <span>{budget.gender==="M" ? "Masculino":"Feminino"}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Estado civil: </strong>
            <span>{budget.maritalStatus}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Estado civil: </strong>
            <span>Solteiro</span>
          </div >
          <div className="budgeInfo_content--itemDiv">
            <strong>Profissão: </strong>
            <span>{budget.profession}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Idade: </strong>
            <span>{budget.age}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Possui filhos: </strong>
            <span>{budget.hasChildren===true ? "Sim":"Não"}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Quantidade de filhos: </strong>
            <span>{budget.childrenQuantity}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Email: </strong>
            <span>{budget.email}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>@ do instagram: </strong>
            <span>{budget.instagram}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Nos segue no instagram: </strong>
            <span>{budget.instagramFollower===true ? "Sim":"Não"}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Tipologia do projeto: </strong>
            <span>{budget.projectTypology}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Nos segue no instagram: </strong>
            <span>sim</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Quantidade de pavimentos: </strong>
            <span>{budget.flooring}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Descrição do projeto: </strong>
            <span>{budget.projectDescription}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Tamanho do lote: </strong>
            <span>{budget.lotArea}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Tamanho do imóvel: </strong>
            <span>{budget.immobileArea}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Estado: </strong>
            <span>{budget.state}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Cidade: </strong>
            <span>{budget.city}</span>
          </div>
          <div className="budgeInfo_content--itemDiv">
            <strong>Local: </strong>
            <span>{budget.local}</span>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

import { useEffect, useState } from "react";
import "./editPostInfo.style.css";
import { useFormInputs, useNotify } from "../../../hook";
import { getPost } from "../../../api/post/getPost.api";
import { alterPost } from "../../../api/post/alterPost.api";
import { useNavigate } from "react-router-dom";
import useGlobalUser from "../../../context/user.context";
import { validateToken } from "../../../constants/functions";

export function EditPostInfo({ postId, handleSelected }) {
  const { formInputs, handleInitialValue, handleChange } = useFormInputs({
    title: "",
    description: "",
    batchArea: "",
    builtArea: "",
    projectYear: "",
    country: "",
    state: "",
    city: "",
    collaborator: "",
  });
  const [categories, setCategories] = useState([]);
  const [categoriesInfo, setCategoriesInfo] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videosInfo, setVideosInfo] = useState([]);
  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState();
  const { notify } = useNotify();
  const navigate = useNavigate();
  const [user, setUser] = useGlobalUser();

  async function fetchPost() {
    try {
      const response = await getPost({ postId: postId });
      handleInitialValue(response.data);
      setVideos(response.data.videos);
      setCategories(response.data.categories);
    } catch (e) {
      notify(e.response.data.message);
    }
  }

  const CATEGORY = (index, category, handleChangeCategories) => {
    return (
      <>
        <span>Título da categoria</span>
        <input
          type="text"
          name="title"
          value={category.title}
          onChange={(e) => handleChangeCategories(index, e)}
          placeholder="Category Title"
        />
        <span>Descrição da categoria</span>
        <input
          type="text"
          name="description"
          value={category.description}
          onChange={(e) => handleChangeCategories(index, e)}
          placeholder="Category Description"
        />
      </>
    );
  };

  const VIDEO = (index, video, handleChangeVideos) => {
    return (
      <>
        <span>Título do vídeo</span>
        <input
          type="text"
          name="title"
          value={video.title}
          onChange={(e) => handleChangeVideos(index, e)}
          placeholder="Video Title"
        />
        <span>Link</span>
        <input
          type="text"
          name="link"
          value={video.link}
          onChange={(e) => handleChangeVideos(index, e)}
          placeholder="Video Link"
        />
      </>
    );
  };

  function addVideo(event) {
    event.preventDefault();
    setVideos([...videos, VIDEO]);
    setVideosInfo([...videosInfo, { title: "", link: "" }]);
  }

  function addCategory(event) {
    event.preventDefault();
    setCategories([...categories, CATEGORY]);
    setCategoriesInfo([...categoriesInfo, { title: "", description: "" }]);
  }

  function handleChangeCategories(index, event) {
    const newCategories = categoriesInfo.map((category, categoryIndex) => {
      if (index === categoryIndex) {
        return { ...category, [event.target.name]: event.target.value };
      }
      return category;
    });
    setCategoriesInfo(newCategories);
  }

  function handleChangeVideos(index, event) {
    const newVideos = videosInfo.map((video, videoIndex) => {
      if (index === videoIndex) {
        return { ...video, [event.target.name]: event.target.value };
      }
      return video;
    });
    setVideosInfo(newVideos);
  }

  function handleChangeImage(event) {
    setImages(event.target.files);
  }

  function handleChangeMainImage(event) {
    setMainImage(event.target.files[0]);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    validateToken(navigate, setUser, notify);
    const formData = new FormData();
    const postRequest = {
      title: formInputs.title,
      description: formInputs.description,
      collaborator: formInputs.collaborator,
      batchArea: formInputs.batchArea,
      builtArea: formInputs.builtArea,
      projectYear: formInputs.projectYear,
      country: formInputs.country,
      state: formInputs.state,
      city: formInputs.city,
      categories: categoriesInfo,
      videos: videosInfo,
    };
    formData.append(
      "request",
      new Blob([JSON.stringify(postRequest)], { type: "application/json" })
    );
    formData.append("mainImage", mainImage);
    Array.from(images).forEach((image, index) => {
      formData.append("images", image);
    });
    try {
      const response = await alterPost({
        postRequest: formData,
        postId: postId,
      });
      notify(response.data.detail);
    } catch (e) {
      notify(e.response.data.message)
    }
  }

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form className="addPost_form" onSubmit={handleSubmit}>
        <h1>Editar</h1>
        <span>Título: </span>
        <input
          onChange={handleChange}
          name="title"
          value={formInputs.title}
          className="addPost_form--input"
          type="text"
        />
        <span>descrição: </span>
        <textarea
          onChange={handleChange}
          name="description"
          value={formInputs.description}
          className="addPost_form--input"
        />
        <span>área do lote: </span>
        <input
          onChange={handleChange}
          name="batchArea"
          value={formInputs.batchArea}
          className="addPost_form--input"
          type="number"
        />
        <span>área construída: </span>
        <input
          onChange={handleChange}
          name="builtArea"
          value={formInputs.builtArea}
          className="addPost_form--input"
          type="number"
        />
        <span>ano do projeto: </span>
        <input
          onChange={handleChange}
          name="projectYear"
          value={formInputs.projectYear}
          className="addPost_form--input"
          type="number"
        />
        <span>país: </span>
        <input
          onChange={handleChange}
          name="country"
          value={formInputs.country}
          className="addPost_form--input"
          type="text"
        />
        <span>estado: </span>
        <input
          onChange={handleChange}
          name="state"
          value={formInputs.state}
          className="addPost_form--input"
          type="text"
        />
        <span>cidade: </span>
        <input
          onChange={handleChange}
          name="city"
          value={formInputs.city}
          className="addPost_form--input"
          type="text"
        />
        <span>colaborador: </span>
        <input
          onChange={handleChange}
          name="collaborator"
          value={formInputs.collaborator}
          className="addPost_form--input"
          type="text"
        />
        {/*<span>Imagens: </span>
        <input type="file" multiple onChange={handleChangeImage} />*/}
        <span>Imagem de capa</span>
        <input type="file" onChange={handleChangeMainImage} />

        {/*{categories.map((category, index) => (
          <div className="addPost_form--inputDiv" key={index}>
            {CATEGORY(index, category, handleChangeCategories)}
          </div>
        ))}
        {videos.map((video, index) => (
          <div className="addPost_form--inputDiv" key={index}>
            {VIDEO(index, video, handleChangeVideos)}
          </div>
        ))}
        <button onClick={addVideo}>Adicionar video</button>
        <button onClick={addCategory}>Adicionar categoria</button>}*/}
        <button onClick={() => handleSelected(undefined, 0, true)}>
          Cancelar
        </button>
        <button>Enviar</button>
      </form>
    </>
  );
}

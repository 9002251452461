import "./removeSlideCard.style.css";
import { ImageShow } from "../../../constants/functions";

export function RemoveSlideCard({ slide, handleSlideSelected, slideSelectedIndex, index }) {

  function handleSelected() {
    if (slideSelectedIndex[`slide${index}`]) {
      handleSlideSelected(undefined, index, true);
    } else {
      handleSlideSelected(slide, index, false);
    }
  }


  return (
    <>
      <div
        onClick={handleSelected}
        className={
          slideSelectedIndex[`slide${index}`]
            ? "removeSlideCard_card--div removeSlideSelected"
            : "removeSlideCard_card--div"
        }
      >
        <img
          className="removeSlideCard_img"
          src={ImageShow(slide.mainImage)}
          alt="Imagem do slide"
        />
        <strong className="slidePostCard_title--strong">
          {slide.postTitle}
        </strong>
      </div>
    </>
  );
}

import { Link } from "react-router-dom";
import "./budgetItem.style.css";

export function BudgetItem({ BudgetId, name, city, state, projectTypology }){
    return(
        <>
            <Link to={`/OrcamentoInfo/${BudgetId}`} className="budgetItem_container">
                <span>Nome: {name}</span>
                <div className="budgetItem_location--div">
                    <span>Cidade: {city}</span>
                    <span>Estado: {state}</span>
                </div>
                <span>{projectTypology}</span>
            </Link>
        </>
    );
}
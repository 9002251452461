import useGlobalUser from "../../../context/user.context";
import "./headerModal.style.css";
import { Link } from "react-router-dom";

export function HeaderModal({ selected, opened, toggleModal }){

    const [user] = useGlobalUser();
    function closeHeader(e){
        if(e.target.classList.contains('headerModal_nav')){
            toggleModal();
        }
    }

    return(
        <>
            <nav onClick={closeHeader} className={opened ? "headerModal_nav open": "headerModal_nav"}>
            <ul className="headerModal_nav--ul">
          <li className="headerModal_nav--ul_li">
            <Link
              onClick={toggleModal}
              className={
                selected === "Home"
                  ? "headerModal_nav--li_text selected"
                  : "headerModal_nav--li_text"
              }
              to="/"
            >
              Início
            </Link>
          </li>
          <li className="headerModal_nav--ul_li">
            <Link
              onClick={toggleModal}
              to={"/Busca"}
              className={
                selected === "Find"
                  ? "headerModal_nav--li_text selected"
                  : "headerModal_nav--li_text"
              }
            >
              Projetos
            </Link>
          </li>
          <li className="headerModal_nav--ul_li">
            <Link
              onClick={toggleModal}
              className={
                selected === "Contact"
                  ? "headerModal_nav--li_text selected"
                  : "headerModal_nav--li_text"
              }
              to="/Contato"
            >
              Contato
            </Link>
          </li>
          <li className="headerModal_nav--ul_li">
            <Link
              onClick={toggleModal}
              className={
                selected === "Instagram"
                  ? "headerModal_nav--li_text selected"
                  : "headerModal_nav--li_text"
              }
              target="_blank"
              to="https://www.instagram.com/basilioarquitetos/"
            >
              Instagram
            </Link>
          </li>
          <li className="headerModal_nav--ul_li">
            <Link
              onClick={toggleModal}
              className={
                selected === "Login"
                  ? "headerModal_nav--li_text selected"
                  : "headerModal_nav--li_text"
              }
              to="/Login"
            >
              {user ? "Meu Perfil" : "Login"}
            </Link>
          </li>
        </ul>
            </nav>
        </>
    );
};
import "./modal.style.css";
import closeButton from "../../../assets/ico/close_button.svg";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { AddPost, RemovePost, EditPost } from "../index";
import { useEffect } from "react";
import { validateToken } from "../../../constants/functions";
import useGlobalUser from "../../../context/user.context";
import { useNotify } from "../../../hook";

export function Modal({ modalOpened, handleModalOpened }) {
  
  const [modalOption, setModalOption] = useState("Add");
  const [user, setUser] = useGlobalUser();
  const navigate = useNavigate();
  const { notify } = useNotify();

  function changeModalOption(option) {
    setModalOption(option);
  }

  function showContent(text) {
    if (text === "Add") {
      return <AddPost/>;
    } else if( text === "Delete"){
      return <RemovePost/>;
    } else if( text === "EditOrder"){
      return <EditPost/>;
    }
  }

  useEffect(()=>{
    validateToken(navigate, setUser, notify);
  })


  return (
    <section className={modalOpened ? "modal_section opened" : "modal_section"}>
      <div className="modal_section--header">
        <Link onClick={handleModalOpened}>
          <img src={closeButton} alt="botão para fechar a modal" />
        </Link>
      </div>
      <div className="modal_section--container">
        <div className="modal_section--navbar">
          <Link
            className={modalOption === "Add" ? "selected" : null}
            onClick={() => changeModalOption("Add")}
          >
            Adicionar Post
          </Link>
          <Link
            className={modalOption === "Delete" ? "selected" : null}
            onClick={() => changeModalOption("Delete")}
          >
            Deletar Post
          </Link>
          <Link
            className={modalOption === "EditOrder" ? "selected" : null}
            onClick={() => changeModalOption("EditOrder")}
          >
            Editar post
          </Link>
        </div>
        {showContent(modalOption)}
      </div>
    </section>
  );
}

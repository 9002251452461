import { useState } from "react";

export function useFormInputs(initialValue){
    const [formInputs, setFormInputs] = useState(initialValue);
    function handleChange(event){
        const {name, value} = event.target;
        setFormInputs((oldFormInputs) => ({
            ...oldFormInputs,
            [name]: value,
        }));
    };

    function handleInitialValue(newInitialValue){
        setFormInputs(newInitialValue);
    }

    return {
        formInputs,
        handleInitialValue,
        handleChange,
    };
};
export function useAddContact(username, email, message){

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            if (username === '' || username === undefined) {
                console.log("O campo nome precisa ser preenchido");
            } else if (email === '' || email === undefined) {
                console.log("O campo email precisa ser preenchido");
            } else if (message === '' || message === undefined) {
                console.log("O campo mensagem precisa ser preenchida");
            } else {
                console.log("enviado");
            };
        } catch (error) {
            console.log("erro");
        };
    };
    return{
        handleSubmit
    };
};
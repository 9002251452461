import "./footer.style.css";
import instagram from "../../../assets/ico/instagram.svg";
import tiktok from "../../../assets/ico/tiktok.svg";
import youtube from "../../../assets/ico/youtube.svg";

export function Footer(){
    return(
        <footer className="footer_main">
            <div className="footer_main--socials">
                <a className="footer_main--socials_link" href="https://www.instagram.com/basilioarquitetos/" target="_blank" rel="noreferrer">
                <img className="footer_main--img" src={instagram} alt="ícone do instagram"/></a>
                <a className="footer_main--socials_link" href="https://www.tiktok.com/@basilioarquitetos" target="_blank" rel="noreferrer">
                <img className="footer_main--img" src={tiktok} alt="ícone do tiktok"/>
                </a>
                <a className="footer_main--socials_link " href="https://www.youtube.com/c/BasilioArquitetos/videos" target="_blank" rel="noreferrer">
                <img className="footer_main--img " src={youtube} alt="ícone do youtube"/>
                </a>
            </div>
            <div className="footer_main--copyright ">
                <span className="footer_copyright--text">© All rights reserved. Basílio Arquitetos 2021-2024.</span>
            </div>
        </footer>
    );
};
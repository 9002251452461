import { API_URL } from "../../constants";
import { axiosInstance } from "../_base/axios.instance";

export async function getAllSlides({ page, SLIDEQUANTITY}){
    
    const response = await axiosInstance.get(
        `${API_URL}/slides/getAll?page=${page}&size=${SLIDEQUANTITY}`,
        {}
    );
    return response;
};
import { API_URL } from "../../constants";
import { axiosInstance } from "../_base/axios.instance";

export async function alterSlide({ slidedId, slideOrder }) {
  const response = await axiosInstance.put(
    `${API_URL}/slides/alter/${slidedId}`,
    {
        slideOrder: slideOrder,
    }
  );
  return response;
}
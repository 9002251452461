import { useState } from "react";

export function useHandleHover(){

    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    return{
        hovered,
        handleMouseEnter,
        handleMouseLeave
    };
};
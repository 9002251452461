import { createBrowserRouter } from "react-router-dom";
import * as uiScreens from "../ui/Screens";

export const router = createBrowserRouter([
  
  {
    path: "/",
    element: <uiScreens.Home/>,
  },
  {
    path: "/login",
    element: <uiScreens.Login/>
  },
  
  {
    path: "/contato",
    element: <uiScreens.Contact/>
  },
  {
    path: "/busca",
    element: <uiScreens.Search/>
  },
  
  {
    path: "/perfil",
    element: <uiScreens.Profile/>
  },
  {
    path: "/post/:postId",
    element: <uiScreens.Post/>
  },
  {
    path: "/$$$$$ssdffaaabasqarqfffff",
    element: <uiScreens.Budget/>
  },
  {
    path: "/orcamentoInfo/:budgetId",
    element: <uiScreens.BudgetInfo/>
  }
]);
import { API_URL } from "../../constants";
import { axiosInstance } from "../_base/axios.instance";

export async function addSlide({ postId }) {
  const response = await axiosInstance.post(
    `${API_URL}/slides/add`,
    {
        postId: postId
    }
  );
  return response;
}
import "./profile.style.css";
import { BudgetList, Footer, Modal, SlideModal } from "../../Components";
import profileSideLogo from "../../../assets/img/profile_side_logo.png";
import homeIco from "../../../assets/ico/profile_side_home_ico.svg";
import inboxIco from "../../../assets/ico/profile_side_inbox_ico.svg";
import taskIco from "../../../assets/ico/profile_side_task_ico.svg";
import searchIco from "../../../assets/ico/profile_side_search_ico.svg";
import notifyIco from "../../../assets/ico/profile_side_notify_ico.svg";
import addIco from "../../../assets/ico/add_ico.svg";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useGlobalUser from "../../../context/user.context";
import { useState } from "react";
import { getProfileImage } from "../../../api/user/getProfileImage.api";
import { ToastContainer } from "react-toastify";
import { validateToken } from "../../../constants/functions";
import { useNotify } from "../../../hook";

export function Profile() {
  const [user, setUser] = useGlobalUser();
  const [modalOpened, setModalOpened] = useState(false);
  const [slideModalOpened, setSlideModalOpened] = useState(false);
  const [profileImgSrc, setProfileImgSrc] = useState("");
  const navigate = useNavigate();
  const { notify } = useNotify();

  function handleLogout() {
    navigate("/Login");
    setUser(null);
  }

  function handleModalOpened() {
    setModalOpened(!modalOpened);
  }

  function handleSlideModalOpened(){
    setSlideModalOpened(!slideModalOpened);
  }

  async function handleProfileImage() {
    try {
      const profileImage = await getProfileImage({
        userId: user.id,
        token: user.token,
      });
      const imageUrl = URL.createObjectURL(profileImage);
      setProfileImgSrc(imageUrl);
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    //handleProfileImage();
    validateToken(navigate, setUser, notify);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <main
        className={modalOpened ? "profile_main modalOpened" : "profile_main"}
      >
        <section className="profile_sidebar">
          <div className="profile_sidebar_top">
            <img
              className="profile_sidebar_logo"
              src={profileSideLogo}
              alt="profile logo"
            />
            <nav className="profile_sidebar--nav">
              <ul className="profile_sidebar--ul">
                <li>
                  <Link to={"/"} className="profile_sidebar--li_div">
                    <img
                      className="profile_sidebar_li--ico"
                      src={homeIco}
                      alt="página inicial ícone"
                    />
                    <span>Página inicial</span>
                  </Link>
                </li>
                <li>
                  <div className="profile_sidebar--li_div">
                    <img
                      className="profile_sidebar_li--ico"
                      src={inboxIco}
                      alt="ícone inbox"
                    />
                    <span>Mensagens</span>
                  </div>
                </li>
                <li>
                  <div className="profile_sidebar--li_div">
                    <img
                      className="profile_sidebar_li--ico"
                      src={taskIco}
                      alt="Ícone menu de demandas"
                    />
                    <span>Demandas</span>
                  </div>
                </li>
                <li>
                  <div className="profile_sidebar--li_div">
                    <img
                      className="profile_sidebar_li--ico"
                      src={searchIco}
                      alt="Ícone barra de buscas"
                    />
                    <span>Pesquisar</span>
                  </div>
                </li>
                <li>
                  <div className="profile_sidebar--li_div">
                    <img
                      className="profile_sidebar_li--ico"
                      src={notifyIco}
                      alt="Ícone menu de notificações"
                    />
                    <span>Notificações</span>
                  </div>
                </li>
                {user?.role === "ADMIN" ? (
                  <li>
                    <Link
                      onClick={handleModalOpened}
                      className="profile_sidebar--li_div"
                    >
                      <img
                        className="profile_sidebar_li--ico"
                        src={addIco}
                        alt="Ícone menu de gerenciar posts"
                      />
                      <span>Gerencia posts</span>
                    </Link>
                  </li>
                ) : null}
                {user?.role === "ADMIN" ? (
                  <li>
                    <Link
                      onClick={handleSlideModalOpened}
                      className="profile_sidebar--li_div"
                    >
                      <img
                        className="profile_sidebar_li--ico"
                        src={addIco}
                        alt="Ícone menu de gerenciar posts"
                      />
                      <span>Gerenciar Slides</span>
                    </Link>
                  </li>
                ) : null}
              </ul>
            </nav>
          </div>
          <div className="profile_sidebar--info_div">
            <div className="profile_sidebar--info_email">
              <p className="profile_sidebar--email">
                {user ? user.email : null}
              </p>
              <p className="profile_sidebar--email_join">acessar e-mail</p>
            </div>
            <div className="profile_sidebar--div_button">
              <Link
                className="profile_sidebar--logout_link"
                onClick={handleLogout}
              >
                sair
              </Link>
            </div>
          </div>
        </section>
        <section className="profile_mainContent">
          <img
            className="profile_profileImg"
            src={profileImgSrc}
            alt="imagem de perfil"
          />
          <BudgetList/>
        </section>
      </main>
      <div
        onClick={handleModalOpened}
        className={modalOpened ? "overlay active" : "overlay"}
      ></div>
      <Modal
        modalOpened={modalOpened}
        handleModalOpened={handleModalOpened}
      />
      <SlideModal
        modalOpened={slideModalOpened}
        handleModalOpened={handleSlideModalOpened}
      />
      <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          style={{ textAlign: "justify", zIndex: "200" }}
        />
      <Footer />
    </>
  );
}

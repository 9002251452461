import { checkToken } from "../../api/auth/checkToken.api";
import imageNotAvailable from "../../assets/img/Image_not_available.png";

export async function validateToken(navigate, setUser, notify) {
  try {
    let user = localStorage.getItem("user");
    if (user) {
      let userObj = JSON.parse(user);
      let token = userObj.token;
      const response = await checkToken({ token: token });
    }
  } catch (e) {
    notify(e.response.data);
    setTimeout(() => {
        setUser(null);
        navigate("/Login");
      }, 2000);
  }
}

export function ImageShow(image){
  if(image === null){
      return imageNotAvailable;
  }
  return image;
};
